/* 
 * @Author: 曹俊杰 
 * @Date: 2021-01-22 15:08:42
 * @Module: 课程文档流
 */
 <template>
  <div class="collegeItem">
    <div class="collegeItem-l">
      <el-image fit="cover"
                :src="data.moment_content.cover"
                style="width: 249px;height: 144px;border-radius: 4px;"></el-image>
    </div>
    <div class="collegeItem-r">
      <div class="collegeItem-r-t ellipsis2">{{data.moment_content.title|trimSpace}}</div>
      <div class="collegeItem-r-c ellipsis2">{{data.moment_content.plain_description|trimSpace}}</div>
      <div class="collegeItem-r-b">
        <span>共{{data.moment_content.chapter_count}}节</span>
        <span class="collegeItem-r-b-solit"></span>
        <span>{{data.visit_count}}看过</span>
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {}
};
 </script>
 <style lang='scss' scoped>
.collegeItem {
  // height: 112px;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  &-r {
    margin-left: 13px;
    &-t {
      width: 408px;
      font-size: 18px;
      font-weight: 500;
      color: #262626;
      line-height: 25px;
      margin-top: 7px;
      height: 50px;
    }
    &-c {
      width: 408px;
      font-size: 13px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
      margin-top: 10px;
      height: 36px;
    }
    &-b {
      font-size: 13px;
      font-weight: 400;
      color: #999999;
      line-height: 18px;
      margin-top: 20px;

      &-solit {
        display: inline-block;
        width: 1px;
        height: 8px;
        background: #eeeeee;
        margin: 0px 10px;
      }
    }
  }
}
</style>
import item from "./item"
import transpondDialog from "./module/transpondDialog"
export default {
	components: {
		item,
		transpondDialog
	},
	data () {
		return {
			transpondDialogVisible: false,
			selectData: null,
			list: [],
			showCommentId: ""
		}
	},
	methods: {
		currentChange (page) {
			this._get_list(page)
		},
		// 底部点击事件
		onclick ({ type, data, index }) {
			//  收藏：collect；转发：transpond；评论：comment；点赞：like；
			if (type == "comment") {
				// this.commentClick(data)
				if (this.showCommentId == data.uuid)
					this.showCommentId = ""
				else
					this.showCommentId = data.uuid

			} else if (type == "like") {
				this.likeClick(data, index)
			} else if (type == "collect") {
				this.collectClick(data, index)
			} else if (type == "transpond") {
				this.transpondClick(data)
			}
		},
		//评论点击
		commentClick ({ type, uuid }) {
			this.$store.dispatch("homepage/toDetail", {
				type,
				uuid,
				query: { first_in_comment: true }
			})
		},
		// 点赞点击
		likeClick (data, index, back) {
			const { has_liked, likes_count, uuid } = this.list[index]
			if (has_liked) {
				this.$set(this.list[index], 'has_liked', 0)
				this.$set(this.list[index], 'likes_count', likes_count - 1)
				if (back) return;
				this._get_like({ uuid, has_liked: 0, likes_count: likes_count - 1 }, index)
			} else {
				this.$set(this.list[index], 'has_liked', 1)
				this.$set(this.list[index], 'likes_count', likes_count + 1)
				this.$store.commit('homepage/showPraiseFn')
				if (back) return;
				this._get_like({ uuid, has_liked: 1, likes_count: likes_count + 1 }, index)
			}
		},
		// 收藏点击
		collectClick (data, index, back) {
			console.log(index)
			const { has_collect, collection_count, uuid } = this.list[index]
			if (has_collect) {
				this.$set(this.list[index], 'has_collect', 0)
				this.$set(this.list[index], 'collection_count', collection_count - 1)
				if (back) return;
				this._get_collect({ uuid, has_collect: 0, collection_count: collection_count - 1 }, index)
			} else {
				this.$set(this.list[index], 'has_collect', 1)
				this.$set(this.list[index], 'collection_count', collection_count + 1)
				if (back) return;
				this._get_collect({ uuid, has_collect: 1, collection_count: collection_count + 1 }, index)
			}
		},
		// 请求点赞
		async _get_like (data, index) {
			const { uuid, has_liked } = data
			const { status, info } = await this.$api.momentLikeApi({ model_id: uuid, type: has_liked })
			if (status == 200) {
				this.$message.success(info)
			} else {
				this.$message.error(info)
				this.likeClick(data, index, true)
			}
		},
		// 请求收藏
		async _get_collect (data, index) {
			const { uuid, has_collect } = data
			const { status, info } = await this.$api.momentCollectApi({ model_id: uuid, type: has_collect })
			if (status == 200) {
				this.$message.success(info)
			} else {
				this.$message.error(info)
				this.collectClick(data, index, true)
			}
		},
		//转发点击
		transpondClick (data) {
			this.selectData = data
			this.transpondDialogVisible = true
		},
		// 转发弹出框回调
		transpond_dialog_change (res) {
			this.transpondDialogVisible = false
			if (res) {
				console.log("成功")
			} else {
				console.log("取消")
			}
			this.selectData = null
		},
		// 头部点击事件
		header_click ({ type, item, index }) {  //follow  关注
			console.log(type, item, index)
			if (type == 'follow') {
				this.follow_click(item, index)
			} else if (type == 'delete') {
				console.log('删除')

				this._get_delete(item, index)
			}
		},
		// 关注
		follow_click (data, index, back) {
			const { has_followed } = this.list[index]
			this.$set(this.list[index], 'has_followed', has_followed === 0 ? 1 : 0)
			if (!back)
				this._get_follow(data, index)
		},
		// 请求点赞
		async _get_follow (data, index) {
			const { user_id } = data
			const { status, info } = await this.$api.followApi({ user_id })
			if (status == 200) {
				this.$message.success(info)
			} else {
				this.$message.error(info)
				this.follow_click(data, index, true)
			}
		},
		// 请求删除
		async _get_delete (data, index) {
			// console.log(data, index)
			const { uuid } = data
			const { status, info } = await this.$api.momentDeleteApi({ uuid })
			if (status == 200) {
				this.$message.success(info)
				this.list.splice(index, 1);
			} else {
				this.$message.error(info)
			}
		},
	}

}
/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 11:22:22
 * @Module: 文章模块
 */
 <template>
  <div class="articleItem-box">
    <div class="articleItem-title">{{data.moment_content.title}}</div>
    <div class="articleItem-content"
         v-viewer="{title:false}">
      <el-image :src="data.moment_content.images[0]"
                v-if="data.moment_content.images"
                style="width:188px;height:122px;border-radius: 4px;"
                fit="cover" />
      <div class="articleItem-content-r">
        {{data.moment_content.description}}
      </div>
    </div>
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  computed: {
    moment_content () {
      return this.data.moment_content
    }
  }, //计算属性
};
 </script>
 <style lang='scss' scoped>
.articleItem-title {
  font-size: 16px;
}
.articleItem-content {
  background: #f9f9f9;
  border-radius: 4px;
  display: flex;
  margin-top: 8px;
  &-r {
    flex: 1;
    // width: 489px;
    margin: 21px 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    height: 80px;
    color: #666;
    font-size: 14px;
  }
}
</style>
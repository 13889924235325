/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 11:23:12
 * @Module: 视频模块
 */
 <template>
  <div @click.stop>
    <div class="videoItem-text"
         @click="onClick">{{data.moment_content.title}}</div>
    <video :src="data.moment_content.play_address"
           :poster="data.moment_content.video_cover_info?data.moment_content.video_cover_info.src:false"
           width="734px"
           height="414px"
           controls
           class="videoItem-content" />
  </div>
</template>
 <script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    onClick () {
      this.$emit("onClick")
    }
  },
};
 </script>
 <style lang='scss' scoped>
.videoItem-content {
  background: #000;
  margin-top: 8px;
  border-radius: 4px;
}
</style>